import React from "react";
import { Navigate } from "react-router-dom";
//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
//pages
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";
import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";
//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import AllComponent from "../pages/AllCompoenent/AllComponent";

import UsersList from "../pages/UserManagement/Users/UsersList";
import RolesList from "../pages/UserManagement/Roles/RolesList";

import BusinessProfile from "../pages/BusinessSetting/BusinessProfile";
import Unauthorized from "../pages/AllCompoenent/Unauthorized";

import Subscription from "../pages/AuthenticationInner/AuthenticationInner/Subscription";
import SourceList from "../pages/Source/SourceList";
import ShiftList from "../pages/Shift Slots/ShiftList";
import UserDashboard from "../pages/DashboardEcommerce/UserDashboard";
import NotificationTableView from "../Components/Common/NotificationTableView";

import RequiredDocumentList from "../pages/RequiredDocument/RequiredDocumentList";
import ServicesList from "../pages/Services/ServicesList";
import ApplicationList from "../pages/ServiceApplication/ApplicationList";
import RetailerList from "../pages/ManageRetailer/RetailerList";
import RechargeList from "../pages/wallet/RechargeList";
import RechargeAdd from "../pages/wallet/RechargeAdd";
import RetailerDashboard from "../pages/DashboardEcommerce/RetailerDashboard";
import Transaction from "../pages/wallet/Transaction";
import RetailerRechargeAdd from "../pages/wallet/RetailerRechargeAdd";


const authProtectedRoutes = [
  // Unauthorized

  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/user-dashboard", component: <UserDashboard /> },
  { path: "/retailer-dashboard/:response?", component: <RetailerDashboard /> },
  //User Profilefilter
  { path: "/profile", component: <UserProfile /> },
  {
    path: "/notification-view/:id/:type",
    component: <NotificationTableView />,
  },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },

  //wallet Route
  { path: "/wallet-retailer-add", component: <RetailerRechargeAdd /> },

  // document list
  { path: "/document-list", component: <RequiredDocumentList /> },
  // services list
  { path: "/services-list", component: <ServicesList /> },
  // application list
  { path: "/application-list/:status?/:dash?", component: <ApplicationList /> },
  // retailer list
  { path: "/retailer-list/:status?", component: <RetailerList /> },
  // Recharge list
  { path: "/recharge-list", component: <RechargeList /> },
  { path: "/transactions-list", component: <Transaction /> },
  // Recharge Create
  { path: "/recharge-create", component: <RechargeAdd /> },
  // Recharge Create




  ///shift-list Routes

  //Source Routes
  { path: "/source-list", component: <SourceList /> },


  // Component
  { path: "/component-list", component: <AllComponent /> },

  // ROLES AND PERMISSIONS ROUTES | USERS
  { path: "/users-list/:status?", component: <UsersList /> },

  // ROLES AND PERMISSIONS ROUTES | ROLES
  { path: "/roles-list", component: <RolesList /> },


  // Business setting
  { path: "/business-setting", component: <BusinessProfile /> },



];

const publicRoutes = [
  // Authentication Page


  { path: "/logout", component: <Logout /> },
  { path: "/unauthorized", component: <Unauthorized /> },
  { path: "/login", component: <Login /> },

  { path: "/", component: <DashboardEcommerce /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
  { path: "/subscription", component: <Subscription /> },
];

export { authProtectedRoutes, publicRoutes };
