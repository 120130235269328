import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Label, Input, Row, Col, Form } from "reactstrap";
import { load } from '@cashfreepayments/cashfree-js';
import AuthUser from "../../helpers/Authuser";

const RetailerRechargeAdd = (props) => {
  let cashfree;
  let initializeSdk = async function () {
    cashfree = await load({ mode: "production" });
  };
  initializeSdk();

  const { http, user } = AuthUser();

  const [retailer, setRetailer] = useState([]);
  const getRetailer = async () => {
    try {
      const res = await http.get("/retailer/list");
      setRetailer(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const [recharge, setRecharge] = useState({
    recharge_retailer_id: user && user.user.uid,
    recharge_amount: "",
    recharge_date: new Date().toLocaleDateString('en-GB').split('/').reverse().join('-'),
  });

  const [tempValid, settempValid] = useState(0);

  useEffect(() => {
    getRetailer();
  }, []);

  const SubmitData = async () => {
    if (recharge.recharge_retailer_id === "") {
      settempValid(1);
    } else if (recharge.recharge_amount === "") {
      settempValid(2);
    } else {
      try {
        const res = await http.post('/recharge/store', recharge);

        if (res.data && res.data.payment_session_id && res.data.order_id) {
          const sessionId = res.data.payment_session_id;
          const orderId = res.data.order_id;

          let checkoutOptions = {
            paymentSessionId: sessionId,
            returnUrl: `https://api.mahaeseva.in/api/callback/${encodeURIComponent(orderId)}/${user.user.uid}`
          };

          cashfree.checkout(checkoutOptions).then(() => {
            console.log("Payment initialized");
          });
        }
      } catch (error) {
        console.error("Error in SubmitData:", error);
      }

      setRecharge({
        ...recharge,
        recharge_amount: ""
      });
    }
  };

  return (
    <div className="page-content">
      <Form>
        <Card className="border p-3 shadow-lg">
          <CardHeader className="card-header border-0">
            <Row className="align-items-center gy-3">
              <div className="col-sm">
                <h5 className="card-title mb-0">Recharge List</h5>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={12} className="mb-2">
                <Label
                  htmlFor="CandidateName-field"
                  className="form-label fw-bold d-flex justify-content-between"
                >
                  <div>Enter Amount to be Added in Wallet <span className="text-danger fw-bold">*</span></div>
                  <div className="text-danger fw-bold">{tempValid === 2 && "Enter the amount to add in Wallet."}</div>
                </Label>
                <Input
                  type="text"
                  className="fw-bold"
                  placeholder="Amount"
                  value={recharge.recharge_amount}
                  onChange={(e) => {
                    settempValid(0);
                    setRecharge({
                      ...recharge,
                      recharge_amount: e.target.value
                    });
                  }}
                />
              </Col>
              <Col lg={12}>
                <button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-primary"
                  onClick={SubmitData}
                >
                  <i className="ri-save-3-line align-bottom me-1"></i>
                  Recharge Now
                </button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
};

export default RetailerRechargeAdd;
