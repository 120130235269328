import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Col,
    Container,
    Input,
    Row,
    CardText,
} from "reactstrap";

import Section from "./Section";
import AuthUser from "../../helpers/Authuser";
import Widgets from "./Widgets";
import RecentOrders from "./RecentOrders";
import { useNavigate, useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { IMG_API_URL } from "../../helpers/url_helper";
import ApplicationAdd from "../ServiceApplication/ApplicationAdd";
import { toast } from "react-toastify";
import PaymentSuccessModal from "./PaymentSuccessModal";

const RetailerDashboard = () => {
    document.title = "Retailer | Dashboard";
    const [modalStates, setModalStates] = useState(false);
    const { user, http, token } = AuthUser();
    const { response } = useParams();
    const [wallet, setWalllet] = useState({});
    // console.log(wallet);
    const getWallet = async () => {
        http
            .get(`/wallet/show/${user.user.uid}`)
            .then((res) => {
                setWalllet(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => { getWallet() }, [user])
    const handleCallback = (data, status) => {
        if (status == 1) {
            toast.success(data)
            setModalStates(!modalStates);
        } else {
            toast.warn(data);
            setModalStates(!modalStates);
        }
    }
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    // console.log(services);
    const getServices = async () => {
        http
            .get("/service/list")
            .then((res) => {
                setServices(res.data.services);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const [findData, setFindData] = useState({});
    const handleApply = (index) => {
        setFindData(services[index]);
        setModalStates(!modalStates);
    }
    useEffect(() => {
        if (token === null) {
            navigate("/login");
        }
        getServices();
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="row">
                        {services.map((data, index) => (

                            <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title fw-bold">{data.service_name}</div>
                                    </div>
                                    <div className="card-body border text-center">
                                        <div>
                                            <img width={200} height={200} src={IMG_API_URL + "/service/" + data.service_image} alt={IMG_API_URL + "/service/" + data.service_image} />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="fw-bold card-title d-flex justify-content-between">
                                            <div className="d-flex">
                                                <i className="bx bx-rupee h4"></i>
                                                <div>{data.service_amount}</div>
                                            </div>
                                            <div>
                                                {wallet && parseInt(wallet.wallet_amount) >= parseInt(data.service_amount) ?

                                                    <div className="btn btn-success" onClick={() => {
                                                        handleApply(index);
                                                    }}>Apply</div>
                                                    : <div className="btn btn-danger"
                                                    ><i className="mdi mdi-alert-outline text-white fs-16 align-middle me-1"></i>Low Balance !</div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>

                </Container>
            </div>
            {modalStates === true ? (
                <ApplicationAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                        setModalStates(false);
                    }}
                    edit_data={findData}
                    checkchang={handleCallback}
                />
            ) : (
                ""
            )}
            {response === "success" && <PaymentSuccessModal />}
        </React.Fragment>
        
    );
};

export default RetailerDashboard;
