import React from "react";
import { Modal } from "react-bootstrap";

const PaymentSuccessModal = () => {
  const [show, setShow] = React.useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-success">Payment Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <i
          className="bi bi-check-circle-fill text-success"
          style={{ fontSize: "4rem" }}
        ></i>
        <p className="mt-3">
          Your payment was processed successfully! Thank you 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-success" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentSuccessModal;
