import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

//import images
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";
import AuthUser from '../../helpers/Authuser';
import NotificationView from './NotificationView';

const WalletDropDown = () => {
    const { http, user } = AuthUser();

    //Dropdown Toggle
    const [isWalletDropDown, setIsWalletDropDown] = useState(false);
    const toggleWalletDropDown = () => {
        setIsWalletDropDown(!isWalletDropDown);
    };
    const [counts, Setcounts] = useState(1);
    //Tab  
    const [RechargeData, SetRechargeData] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    const [wallet, setWalllet] = useState({});
    const getWallet = async () => {
        http
            .get(`/wallet/show/${user.user.uid}`)
            .then((res) => {
                setWalllet(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => { getWallet() }, [user])


    useEffect(() => {
        document.title = "Recharge | E-DOC";

        http
            .get(`/recharge/list`)
            .then(function (response) {

                user.user.u_type === 3 ?
                    SetRechargeData(

                        response.data.filter((item) => item.recharge_retailer_id == user.user.uid)



                    ) : SetRechargeData(response.data)


            })
            .catch(function (error) {
                console.log(error);

            });
    }, [counts]);


    return (
        <React.Fragment>
            <Dropdown isOpen={isWalletDropDown} toggle={toggleWalletDropDown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='ri-wallet-fill fs-22'></i>
                    <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{wallet.wallet_amount ? wallet.wallet_amount : 0}<span
                            className="visually-hidden">unread messages</span></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white">MY WALLET  </h6>
                                </Col>

                            </Row>
                        </div>

                        <div className="px-2 pt-2">

                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                                <div className="p-2">
                                    <div className="text-center empty-cart" id="empty-cart" style={{ display: "none" }}>
                                        <div className="avatar-md mx-auto my-3">
                                            <div className="avatar-title bg-info-subtle text-info fs-36 rounded-circle">
                                                <i className='bx bx-cart'></i>
                                            </div>
                                        </div>
                                        {/* <h5 className="mb-3">Your Cart is Empty!</h5>
                                        <Link to="/apps-ecommerce-products" className="btn btn-success w-md mb-3">Shop Now</Link> */}
                                    </div>

                                    {RechargeData.slice(0, 5).reverse().map((item, key) => (<div className="d-block dropdown-item text-wrap dropdown-item-cart px-3 py-2" key={key}>
                                        <div className="d-flex align-items-center">
                                            <div className="px-2">
                                                <h5 className="m-0 fw-normal">{key + 1}</h5>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mt-0 mb-1 fs-14">
                                                {new Date(item.recharge_date).toISOString().split('T')[0]}
                                                </h6>

                                            </div>
                                            <div className="px-2">
                                                <h5 className="m-0 fw-normal">₹ <span className="cart-item-price">{item.recharge_amount}</span></h5>
                                            </div>

                                        </div>
                                    </div>))}
                                </div>

                                <div className="my-3 text-center">
                                    <Link
                                        to={'/wallet-retailer-add'}
                                        className="btn btn-soft-success waves-effect waves-light ml-1"
                                    // onClick={()=>setActiveTab('0')}
                                    >
                                        Add Recharge
                                    </Link>
                                    <Link
                                        to={'/recharge-list'}
                                        className="btn btn-soft-success waves-effect waves-light m-1"
                                    // onClick={()=>setActiveTab('0')}
                                    >
                                        View History
                                    </Link>

                                </div>
                            </SimpleBar>

                        </TabPane>



                    </TabContent>
                </DropdownMenu>
            </Dropdown>

        </React.Fragment>
    );
};

export default WalletDropDown;